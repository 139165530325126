<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>
        <template v-slot:right>
          <Button
            icon="pi pi-search"
            class="p-mr-2 p-button-primary"
            @click="openSearchForm"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="registerList"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column header="Employee" headerStyle="width: 200px">
            <template #body="slotProps">
              {{ slotProps.data.employeeFirstname }}
              {{ slotProps.data.employeeName }} ({{
                slotProps.data.employeeId
              }})
            </template>
          </Column>
          <Column header="Opening Date/Time" headerStyle="width: 200px">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }} /
              {{ formatTime(slotProps.data.time) }}
            </template>
          </Column>
          <Column header="Cash Opening Balance" headerStyle="width: 180px">
            <template #body="slotProps">
              <b>$ {{ formatAmount(slotProps.data.totalBalance) }}</b>
            </template>
          </Column>
          <Column header="Closing Date/Time" headerStyle="width: 180px">
            <template #body="slotProps">
              <span
                v-if="
                  slotProps.data.closingDate != '' &&
                  slotProps.data.closingTime != ''
                "
              >
                {{ formatDate(slotProps.data.closingDate) }} /
                {{ formatTime(slotProps.data.closingTime) }}</span
              >
            </template>
          </Column>
          <Column header="Cash Closing Balance" headerStyle="width: 180px"
            >>
            <template #body="slotProps">
              <b> $ {{ formatAmount(slotProps.data.closingBalance) }} </b>
            </template>
          </Column>
          <Column header="Created Store" headerStyle="width: 300px">
            <template #body="slotProps">
              {{ slotProps.data.mall }} | {{ slotProps.data.isAdmin }}
            </template>
          </Column>
          <Column header="Mode" headerStyle="width: 100px">
            <template #body="slotProps">
              <Button
                disabled="disabled"
                icon="pi pi-lock"
                v-if="slotProps.data.statusType == 'close'"
                :label="slotProps.data.statusType.toUpperCase()"
                style="width: 80px"
                class="p-button-success p-p-1"
              />
              <Button
                disabled="disabled"
                icon="pi pi-unlock"
                v-if="slotProps.data.statusType == 'open'"
                :label="slotProps.data.statusType.toUpperCase()"
                style="width: 80px"
                class="p-button-danger p-p-1"
              />
            </template>
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 200px"
            header="Action"
          >
            <template #body="slotProps" class="p-mt-2 p-mb-2">
              <span class="p-buttonset">
                <Button
                  class="p-button-warning p-button-rounded"
                  @click="closeDrawer(slotProps.data.registerId)"
                  label="Close Drawer"
                  icon="pi pi-lock"
                  v-if="slotProps.data.statusType == 'open'"
                />
                <Button
                  class="p-button-danger  p-button-rounded"
                  @click="openDrawer(slotProps.data.registerId)"
                  label="Reopen Drawer"
                  icon="pi pi-unlock"
                  v-if="slotProps.data.statusType == 'close'"
                />
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="searchDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Search Cash Register
      </h4>
    </template>
    <div class="p-field">
      <label for="name">Date From:</label>
      <input
        type="date"
        v-model.trim="searchForm.date1"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field">
      <label for="name">Date To:</label>
      <input
        type="date"
        v-model.trim="searchForm.date2"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field">
      <label for="storesList">Filter Stores</label>
      <MultiSelect
        class="multiselect-custom"
        v-model="searchForm.assignedBranchId"
        emptyFilterMessage="No Store found"
        :options="myStores"
        scrollHeight="300px"
        placeholder="Select Stores"
      >
        <template #value="slotProps">
          <div
            class="country-item country-item-value"
            v-for="option of slotProps.value"
            :key="option.code"
          >
            <div>Store {{ option.mall }} | Code {{ option.code }}</div>
          </div>
          <template v-if="!slotProps.value || slotProps.value.length === 0">
            Select Stores
          </template>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>
              Store {{ slotProps.option.mall }} | Code
              {{ slotProps.option.code }}
            </div>
          </div>
        </template>
      </MultiSelect>
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Search"
        icon="pi pi-search"
        class="p-button-primary"
        @click="loadList()"
      />
    </template>
  </Dialog>

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import RegisterService from "../../service/RegisterService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import { branchDetail } from "../../pages/announcements/IAnnounce";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { AssociatePinVerification },
  title: 'Manager Cash Register'
})

export default class ManagerCashRegister extends Vue {
  private verificationType = "";
  private associateID = "";
  private lists = [];
  private dialogTitle = "Enter Beginning Cash Drawer Total";
  private registerService;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Cash Register" }];
  private toast;
  private iconName = "pi pi-plus-circle";
  private countDrawerDialog = false;
  private openDialog = false;
  private submitDialog = false;
  private statusType = "close";
  private submitted = false;
  private openRecon = false;
  private searchDialog = false;
  private dialogStatus = "new";
  private myStores: Array<branchDetail> = [];
  private registerId = "";
  private registerList = [
    {
      employeeFirstname: "",
      employeeName: "",
      employeeId: "",
      date: "",
      time: "",
      totalBalance: 0,
      closingDate: "",
      closingTime: "",
      closingBalance: 0,
      mall: "",
      code: "",
      isAdmin: "",
      registerId: "",
    },
  ];


  private searchForm = {
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.registerService = new RegisterService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.verificationType = "manager";
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;
    this.registerService.getRegisters(this.searchForm).then((data) => {
      const registerList = this.camelizeKeys(data);
      this.registerList = registerList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  openSearchForm() {
    this.searchDialog = true;

    this.registerService.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  closePINDialog(params) {
    this.associateID = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }

  closeDrawer(registerID)
  {
    this.registerService.closeManagerRegisters(registerID).then((res) => {
        this.toast.handleResponse(res);
        this.loadList();
    });
  }

  openDrawer(registerID)
  {
    this.registerService.openManagerRegisters(registerID).then((res) => {
       this.toast.handleResponse(res);
       this.loadList();
    });
  }
}
</script>

<style scoped>
.cash-register tr th,
td {
  width: 40vw;
  padding: 2px;
  text-align: center;
}

.p-inputtext {
  padding: 0.2rem 0.75rem;
}

.register-total {
  background-color: #004c97;
  color: #fff;
}
</style>

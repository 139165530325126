
import { Options, Vue } from "vue-class-component";
import RegisterService from "../../service/RegisterService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import router from "../../router";
import { branchDetail } from "../../pages/announcements/IAnnounce";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { AssociatePinVerification },
  title: 'Cash Register'
})
export default class CashRegister extends Vue {
  private verificationType = "";
  private associateID = "";
  private lists = [];
  private dialogTitle = "Enter Beginning Cash Drawer Total";
  private registerService;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Cash Register" }];
  private toast;
  private iconName = "pi pi-plus-circle";
  private countDrawerDialog = false;
  private openDialog = false;
  private submitDialog = false;
  private statusType = "close";
  private submitted = false;
  private openRecon = false;
  private searchDialog = false;
  private dialogStatus = "new";
  private myStores: Array<branchDetail> = [];
  private registerId = "";
  private registerList = [
    {
      employeeFirstname: "",
      employeeName: "",
      employeeId: "",
      date: "",
      time: "",
      totalBalance: 0,
      closingDate: "",
      closingTime: "",
      closingBalance: 0,
      mall: "",
      code: "",
      isAdmin: "",
      registerId: "",
    },
  ];

  private registerReconList = {
    statedStartingBalance: 0,
    actualStartingBalance: 0,
    statedCash: 0,
    actualCash: 0,
    statedTipsCollected: 0,
    actualTipsCollected: 0,
    statedVisa: 0,
    actualVisa: 0,
    statedMasterCard: 0,
    actualMasterCard: 0,
    statedAMEX: 0,
    actualAMEX: 0,
    statedDiscover: 0,
    actualDiscover: 0,
    statedGiftCard: 0,
    actualGiftCard: 0,
    statedDebitCard: 0,
    actualDebitCard: 0,
    notes: "",
  };

  private totalCash = {
    totalHundreds: 100,
    totalFifties: 50,
    totalTwenties: 20,
    totalTens: 10,
    totalFives: 5,
    totalCoinsTwos: 2,
    totalCoinsOnes: 1,
    totalCoinsTwoFive: 0.25,
    totalCoinsOneZero: 0.1,
    totalCoinsZeroFive: 0.05,
  };

  private multipliers = {
    totalHundreds: 0,
    totalFifties: 0,
    totalTwenties: 0,
    totalTens: 0,
    totalFives: 0,
    totalCoinsTwos: 0,
    totalCoinsOnes: 0,
    totalCoinsTwoFive: 0,
    totalCoinsOneZero: 0,
    totalCoinsZeroFive: 0,
  };

  private totalRegisterCash = {
    totalHundreds: 0,
    totalFifties: 0,
    totalTwenties: 0,
    totalTens: 0,
    totalFives: 0,
    totalCoinsTwos: 0,
    totalCoinsOnes: 0,
    totalCoinsTwoFive: 0,
    totalCoinsOneZero: 0,
    totalCoinsZeroFive: 0,
    totalAmonut: 0,
  };

  private countDrawer = {
    associateID: "",
    reason: "",
  };

  private searchForm = {
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.registerService = new RegisterService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.verificationType = "associate";
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;
    this.registerService.getRegisters(this.searchForm).then((data) => {
      const registerList = this.camelizeKeys(data);
      this.registerList = registerList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get totalCashDrawer() {
    this.totalRegisterCash.totalHundreds = this.formatAmount(
      this.multipliers.totalHundreds * this.totalCash.totalHundreds
    );
    this.totalRegisterCash.totalFifties = this.formatAmount(
      this.multipliers.totalFifties * this.totalCash.totalFifties
    );
    this.totalRegisterCash.totalTwenties = this.formatAmount(
      this.multipliers.totalTwenties * this.totalCash.totalTwenties
    );
    this.totalRegisterCash.totalTens = this.formatAmount(
      this.multipliers.totalTens * this.totalCash.totalTens
    );
    this.totalRegisterCash.totalFives = this.formatAmount(
      this.multipliers.totalFives * this.totalCash.totalFives
    );
    this.totalRegisterCash.totalCoinsTwos = this.formatAmount(
      this.multipliers.totalCoinsTwos * this.totalCash.totalCoinsTwos
    );
    this.totalRegisterCash.totalCoinsOnes = this.formatAmount(
      this.multipliers.totalCoinsOnes * this.totalCash.totalCoinsOnes
    );
    this.totalRegisterCash.totalCoinsTwoFive = this.formatAmount(
      this.multipliers.totalCoinsTwoFive * this.totalCash.totalCoinsTwoFive
    );
    this.totalRegisterCash.totalCoinsOneZero = this.formatAmount(
      this.multipliers.totalCoinsOneZero * this.totalCash.totalCoinsOneZero
    );
    this.totalRegisterCash.totalCoinsZeroFive = this.formatAmount(
      this.multipliers.totalCoinsZeroFive * this.totalCash.totalCoinsZeroFive
    );

    return this.totalRegisterCash;
  }

  get sumDrawerCash() {
    let totalAmonut = 0;

    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalHundreds);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalFifties);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalTwenties);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalTens);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalFives);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalCoinsTwos);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalCoinsOnes);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsTwoFive);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsOneZero);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsZeroFive);

    this.totalRegisterCash.totalAmonut = totalAmonut;

    return totalAmonut.toFixed(2);
  }

  acceptDrawer() {
    this.registerService
      .acceptDawer(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  openNewStatus() {
    this.clearMultipiers();
    this.dialogTitle = "Enter Beginning Cash Drawer Total";
    this.dialogStatus = "new";
    this.openDialog = true;

    //FETCH THE PREVIOUS DRAWER AMOUNT
    this.registerService.getPreviousDayAmount().then((res) => {
      const amounts = this.camelizeKeys(res);
      this.multipliers.totalHundreds      = amounts.totalHundreds;
      this.multipliers.totalFifties       = amounts.totalFifties;
      this.multipliers.totalTwenties      = amounts.totalTwenties;
      this.multipliers.totalTens          = amounts.totalTens;
      this.multipliers.totalFives         = amounts.totalFives;
      this.multipliers.totalCoinsTwos     = amounts.totalCoinsTwos;
      this.multipliers.totalCoinsOnes     = amounts.totalCoinsOnes;
      this.multipliers.totalCoinsTwoFive  = amounts.totalCoinsTwoFive;
      this.multipliers.totalCoinsOneZero  = amounts.totalCoinsOneZero;
      this.multipliers.totalCoinsZeroFive = amounts.totalCoinsZeroFive;
    });
  }

  openDayStatus(registerID) {
    this.clearMultipiers();

    this.dialogTitle = "Update Beginning Cash Drawer Total";
    this.dialogStatus = "open_day";
    this.registerId = registerID;
    this.openDialog = true;

    this.registerService.getOpenDawer(registerID).then((res) => {
      const openDay = this.camelizeKeys(res.cash_register_open);
      this.multipliers.totalHundreds = openDay.hundreds;
      this.multipliers.totalFifties = openDay.fifties;
      this.multipliers.totalTwenties = openDay.twenty;
      this.multipliers.totalTens = openDay.ten;
      this.multipliers.totalFives = openDay.five;
      this.multipliers.totalCoinsTwos = openDay.ctwo;
      this.multipliers.totalCoinsOnes = openDay.cone;
      this.multipliers.totalCoinsTwoFive = openDay.czerotwofive;
      this.multipliers.totalCoinsOneZero = openDay.conezero;
      this.multipliers.totalCoinsZeroFive = openDay.czerozerofive;
      this.statusType = res.status_type;
    });
  }

  closeDayStatus(registerID) {
    this.clearMultipiers();
    this.dialogTitle = "End of the Day Cash Drawer Reconciliation";
    this.dialogStatus = "close_day";
    this.openDialog = true;
    this.registerId = registerID;

    this.registerService.getOpenDawer(registerID).then((res) => {
      const closeDay = this.camelizeKeys(res.cash_register_end);
      this.statusType = res.status_type;
      this.multipliers.totalHundreds = closeDay.hundreds;
      this.multipliers.totalFifties = closeDay.fifties;
      this.multipliers.totalTwenties = closeDay.twenty;
      this.multipliers.totalTens = closeDay.ten;
      this.multipliers.totalFives = closeDay.five;
      this.multipliers.totalCoinsTwos = closeDay.ctwo;
      this.multipliers.totalCoinsOnes = closeDay.cone;
      this.multipliers.totalCoinsTwoFive = closeDay.czerotwofive;
      this.multipliers.totalCoinsOneZero = closeDay.conezero;
      this.multipliers.totalCoinsZeroFive = closeDay.czerozerofive;
    });
  }

  updateDayDrawer() {
    this.registerService
      .updateOpenDay(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut,
        this.registerId
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  updateCloseDay() {
    this.registerService
      .updateCloseDay(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut,
        this.registerId
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  closeDrawerScreen() {
    this.openDialog = false;
  }

  clearMultipiers() {
    this.multipliers = {
      totalHundreds: 0,
      totalFifties: 0,
      totalTwenties: 0,
      totalTens: 0,
      totalFives: 0,
      totalCoinsTwos: 0,
      totalCoinsOnes: 0,
      totalCoinsTwoFive: 0,
      totalCoinsOneZero: 0,
      totalCoinsZeroFive: 0,
    };

    this.totalRegisterCash.totalAmonut = 0;
  }

  openReconciliation(registerID) {
    this.openRecon = true;
    this.dialogStatus = "cash_summary";
    this.registerId = registerID;

    this.registerService.getReconPaymentList(this.registerId).then((data) => {
      const res = this.camelizeKeys(data);
      this.registerReconList.statedStartingBalance = res.openingBalance;
      this.registerReconList.statedCash = res.statedCash;
      this.registerReconList.actualCash = res.closingBalance;
      this.registerReconList.statedTipsCollected = res.statedTips;
      this.registerReconList.statedVisa = res.statedVisa;
      this.registerReconList.statedMasterCard = res.statedMaster;
      this.registerReconList.statedAMEX = res.statedAmex;
      //this.registerReconList.statedDiscover = res.statedDiscover;
      this.registerReconList.statedGiftCard = res.statedGiftcard;
      this.registerReconList.statedDebitCard = res.statedDebit;

      // this.registerReconList.actualCash = res.paymentSummery.cash;
      this.registerReconList.actualTipsCollected = res.paymentSummery.tip;
      this.registerReconList.actualVisa = res.paymentSummery.visa;
      this.registerReconList.actualMasterCard = res.paymentSummery.master;
      this.registerReconList.actualAMEX = res.paymentSummery.amex;
      this.registerReconList.actualDiscover = res.paymentSummery.discover;
      this.registerReconList.actualGiftCard = res.paymentSummery.gift;
      this.registerReconList.actualDebitCard = res.paymentSummery.debit;
      this.registerReconList.notes = res.paymentSummery.notes;

      this.multipliers.totalHundreds      = res.totalHundreds;
      this.multipliers.totalFifties       = res.totalFifties;
      this.multipliers.totalTwenties      = res.totalTwenties;
      this.multipliers.totalTens          = res.totalTens;
      this.multipliers.totalFives         = res.totalFives;
      this.multipliers.totalCoinsTwos     = res.totalCoinsTwos;
      this.multipliers.totalCoinsOnes     = res.totalCoinsOnes;
      this.multipliers.totalCoinsTwoFive  = res.totalCoinsTwoFive;
      this.multipliers.totalCoinsOneZero  = res.totalCoinsOneZero;
      this.multipliers.totalCoinsZeroFive = res.totalCoinsZeroFive;

      this.statusType = res.statusType;
    });
  }

  // checkDrawerStatus(registerID) {
  //   this.registerId = registerID;
  //   this.registerService.getDrawerStatus(registerID).then((data) => {
  //     const res = this.camelizeKeys(data);
  //     this.statusType = res.statusType;
  //   });
  // }

  get sumStatedAmount() {
    let amount = 0;

    amount = amount + Number(this.registerReconList.statedStartingBalance);
    amount = amount + Number(this.registerReconList.statedCash);
    amount = amount + Number(this.registerReconList.statedTipsCollected);
    amount = amount + Number(this.registerReconList.statedVisa);
    amount = amount + Number(this.registerReconList.statedMasterCard);
    amount = amount + Number(this.registerReconList.statedAMEX);
    amount = amount + Number(this.registerReconList.statedDiscover);
    amount = amount + Number(this.registerReconList.statedGiftCard);
    amount = amount + Number(this.registerReconList.statedDebitCard);

    return amount.toFixed(2);
  }

  get sumActualAmount() {
    let amount = 0;

    amount = amount + Number(this.registerReconList.actualStartingBalance);
    amount = amount + Number(this.registerReconList.actualCash);
    amount = amount + Number(this.registerReconList.actualTipsCollected);
    amount = amount + Number(this.registerReconList.actualVisa);
    amount = amount + Number(this.registerReconList.actualMasterCard);
    amount = amount + Number(this.registerReconList.actualAMEX);
    amount = amount + Number(this.registerReconList.actualDiscover);
    amount = amount + Number(this.registerReconList.actualGiftCard);
    amount = amount + Number(this.registerReconList.actualDebitCard);

    return amount.toFixed(2);
  }

  get getDifference() {
    const diff = Number(this.sumActualAmount) - Number(this.sumStatedAmount);
    return diff.toFixed(2);
  }

  closeReconScreen() {
    this.openRecon = false;
  }

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }

  savePaymentDetails() {
    this.registerService
      .savePaymentDetails(
        this.registerId,
        this.associateID,
        this.registerReconList,
        this.sumActualAmount,
        this.multipliers,
        this.getDifference
      )
      .then((res) => {
        //this.openRecon = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  openCountDrawer() {
    let source = "";

    if (this.dialogStatus == "new") {
      source = "open_cash_register";
    } else if (this.dialogStatus == "open_day") {
      source = "open_day_cash_register";
    } else if (this.dialogStatus == "close_day") {
      source = "close_day_cash_register";
    } else if (this.dialogStatus == "cash_summary") {
      source = "cash_summary";
    }

    this.submitted = true;

    if (this.countDrawer.associateID != "" && this.countDrawer.reason != "") {
      this.registerService
        .openCashDrawer(this.countDrawer, source)
        .then((res) => {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
          this.countDrawerDialog = false;
          this.countDrawer = {
            associateID: "",
            reason: "",
          };
        });
    } else {
      this.toast.showError("Please fill both the required fields");
    }
  }

  submitCashRegister() {
    this.registerService.submitDrawer(this.registerId).then((res) => {
      //SHOW NOTIFICATION
      this.submitDialog = false;
      this.openRecon = false;
      this.toast.handleResponse(res);
      this.loadList();
    });
  }

  openSearchForm() {
    this.searchDialog = true;

    this.registerService.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  closePINDialog(params) {
    this.associateID = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }
  amountNeedToDeposit()
  {
    let depositAmt = Number(this.sumActualAmount) - Number(this.sumDrawerCash);

    if(depositAmt < 0)
    {
      depositAmt = 0;
    }


    return this.formatAmount(depositAmt);
  }

  reset(amount)
  {
    if(amount == 0)
    {
      amount = '';
    }

    return amount;
  }


  makeFocus(event)
  {
   event.target.select()
  }

}

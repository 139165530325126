
import { Options, Vue } from "vue-class-component";
import RegisterService from "../../service/RegisterService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import { branchDetail } from "../../pages/announcements/IAnnounce";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { AssociatePinVerification },
  title: 'Manager Cash Register'
})

export default class ManagerCashRegister extends Vue {
  private verificationType = "";
  private associateID = "";
  private lists = [];
  private dialogTitle = "Enter Beginning Cash Drawer Total";
  private registerService;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Cash Register" }];
  private toast;
  private iconName = "pi pi-plus-circle";
  private countDrawerDialog = false;
  private openDialog = false;
  private submitDialog = false;
  private statusType = "close";
  private submitted = false;
  private openRecon = false;
  private searchDialog = false;
  private dialogStatus = "new";
  private myStores: Array<branchDetail> = [];
  private registerId = "";
  private registerList = [
    {
      employeeFirstname: "",
      employeeName: "",
      employeeId: "",
      date: "",
      time: "",
      totalBalance: 0,
      closingDate: "",
      closingTime: "",
      closingBalance: 0,
      mall: "",
      code: "",
      isAdmin: "",
      registerId: "",
    },
  ];


  private searchForm = {
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.registerService = new RegisterService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.verificationType = "manager";
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;
    this.registerService.getRegisters(this.searchForm).then((data) => {
      const registerList = this.camelizeKeys(data);
      this.registerList = registerList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  openSearchForm() {
    this.searchDialog = true;

    this.registerService.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  closePINDialog(params) {
    this.associateID = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }

  closeDrawer(registerID)
  {
    this.registerService.closeManagerRegisters(registerID).then((res) => {
        this.toast.handleResponse(res);
        this.loadList();
    });
  }

  openDrawer(registerID)
  {
    this.registerService.openManagerRegisters(registerID).then((res) => {
       this.toast.handleResponse(res);
       this.loadList();
    });
  }
}
